@tailwind base;
@tailwind components;
@tailwind utilities;

$dark-blue: #2b5c81;
$light-blue: #178aad;

html, body {
    height: 100%;
    max-width: 100%;
}

body {
    margin: 0;
    font-family: "Lato", "Helvetica Neue", sans-serif;
}

.light-color {
    color: $light-blue
}

.dark-color {
    color: $dark-blue
}

ref-dark-color {
  color: #2b5c81;
}

ref-light-color {
  color: #178aad;
}

.text-aidant-dark {
    color: #2b5c81;
}

.borderhihi {
    border-bottom: 10px solid #2b5c81;;
}

.text-aidant-light {
    color: #178aad;
}

.text-neutral-68 {
    color: #444444;
};

.text-color-aidant-dark {
    color: #184E77;
}

.text-color-white {
    color: #ffffff;
}

.text-color-aidant-light {
    color: #168AAD;
}

.bg-color-aidant-dark {
    background-color: #184E77;
}
